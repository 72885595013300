import * as React from 'react';
import { Form, Row, Col, Container, Button } from 'react-bootstrap';

import './styles.css';

interface Results {
  savingsAmt: number;
  needsAmt: number;
  wantsAmt: number;

  savingsPreTaxPct: number;
  needsPreTaxPct: number;
  wantsPreTaxPct: number;

  savingsPostTaxPct: number;
  needsPostTaxPct: number;
  wantsPostTaxPct: number;
}

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function App() {
  // Pre and post tax income, in dollars
  const [preTaxIncome, setPreTaxIncome] = React.useState<number>();
  const [postTaxIncome, setPostTaxIncome] = React.useState<number>();
  const [results, setResults] = React.useState<Results>();

  React.useEffect(() => {
    loadResults();
  }, []);

  React.useEffect(() => {
    setResults(computeResults());
  }, [preTaxIncome, postTaxIncome]);

  function computeResults() {
    if (!preTaxIncome || !postTaxIncome) {
      return {
        savingsAmt: 0,
        needsAmt: 0,
        wantsAmt: 0,

        savingsPreTaxPct: 0,
        needsPreTaxPct: 0,
        wantsPreTaxPct: 0,

        savingsPostTaxPct: 0,
        needsPostTaxPct: 0,
        wantsPostTaxPct: 0,
      }
    }

    // Step 1: Compute amounts
    // Savings is 20% of pre-tax rounded up to 2 decimal places
    const savingsAmt = Math.ceil(preTaxIncome * 0.2 * 100) / 100;
    // Savings is 50% of post-tax rounded up to 2 decimal places
    const needsAmt = Math.ceil(postTaxIncome * 0.5 * 100) / 100;
    // Wants is postTaxIncome - savings - needs, rounded down to 2 decimal places
    const wantsAmt = Math.floor((postTaxIncome - savingsAmt - needsAmt) * 100) / 100;

    const savingsPreTaxPct = Math.floor((savingsAmt / preTaxIncome) * 100 * 100) / 100;
    const needsPreTaxPct = Math.floor((needsAmt / preTaxIncome) * 100 * 100) / 100;
    const wantsPreTaxPct = Math.floor((wantsAmt / preTaxIncome) * 100 * 100) / 100;

    // Step 2: Compute percentages relative to post-tax income, rounded to 2 decimal places
    const savingsPostTaxPct = Math.floor((savingsAmt / postTaxIncome) * 100 * 100) / 100;
    const needsPostTaxPct = Math.floor((needsAmt / postTaxIncome) * 100 * 100) / 100;
    const wantsPostTaxPct = Math.floor((wantsAmt / postTaxIncome) * 100 * 100) / 100;

    return {
      savingsAmt,
      needsAmt,
      wantsAmt,

      savingsPreTaxPct,
      needsPreTaxPct,
      wantsPreTaxPct,

      savingsPostTaxPct,
      needsPostTaxPct,
      wantsPostTaxPct,
    };
  }

  function saveResults() {
    if (preTaxIncome && postTaxIncome) {
      localStorage.setItem('preTaxIncome', preTaxIncome.toString());
      localStorage.setItem('postTaxIncome', postTaxIncome.toString());
    }
  }

  function loadResults() {
    const preTaxIncome = localStorage.getItem('preTaxIncome');
    const postTaxIncome = localStorage.getItem('postTaxIncome');
    if (preTaxIncome && postTaxIncome) {
      setPreTaxIncome(parseFloat(preTaxIncome));
      setPostTaxIncome(parseFloat(postTaxIncome));
    }
  }

  function resetCalculator() {
    if (window.confirm('Are you sure you want to reset the calculator? Any saved results will not be cleared.')) {
      setPreTaxIncome(0);
      setPostTaxIncome(0);
    }
  }

  function deleteSavedResults() {
    if (window.confirm('Are you sure you want to delete saved results from your browser? This cannot be undone!')) {
      localStorage.removeItem('preTaxIncome');
      localStorage.removeItem('postTaxIncome');

      setPreTaxIncome(0);
      setPostTaxIncome(0);
    }
  }

  return (
    <Container>
      <h1>50/30/20 Rule Calculator</h1>

      <Form className="form">
        <Row>
          <Col md={6} className="form__pre-tax-income">
            <Form.Group controlId="preTaxIncome">
              <Form.Label>Pre-Tax Income</Form.Label>
              <Form.Control
                type="number"
                min="0.00"
                step="0.01"
                value={preTaxIncome}
                onChange={(e) => {
                  setPreTaxIncome(e.target.value ? parseFloat(e.target.value) : 0)
                }}
              />
            </Form.Group>
          </Col>

          <Col md={6} className="form__post-tax-income">
            <Form.Group controlId="postTaxIncome">
              <Form.Label>Post-Tax Income</Form.Label>
              <Form.Control
                type="number"
                min="0.00"
                step="0.01"
                value={postTaxIncome}
                onChange={(e) => {
                  setPostTaxIncome(e.target.value ? parseFloat(e.target.value) : 0)
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {results && (
        <div className="results">
          <div className="taxed-amount">
            <span className="results__type">
              Amount Taxed:&nbsp;
            </span>
            <span className="results__amount">
              -{moneyFormatter.format((preTaxIncome && postTaxIncome) ? preTaxIncome - postTaxIncome : 0)}&nbsp;
            </span>
            <span className="results__percent">
              (-{(preTaxIncome && postTaxIncome) ? Math.floor(((preTaxIncome - postTaxIncome) / preTaxIncome) * 100 * 100) / 100 : 0}% BT$)
            </span>
          </div>

          <div className="earned-amount">
            <div>
              <span className="results__type">
                Savings:&nbsp;
              </span>
              <span className="results__amount">
                {moneyFormatter.format(results.savingsAmt)}&nbsp;
              </span>
              <span className="results__percent">
                ({results.savingsPostTaxPct}% AT$ | {results.savingsPreTaxPct}% BT)
              </span>
            </div>

            <div>
              <span className="results__type">
                Needs:&nbsp;
              </span>
              <span className="results__amount">
                {moneyFormatter.format(results.needsAmt)}&nbsp;
              </span>
              <span className="results__percent">
                ({results.needsPostTaxPct}% AT$ | {results.needsPreTaxPct}% BT)
              </span>
            </div>

            <div>
              <span className="results__type">
                Wants:&nbsp;
              </span>
              <span className="results__amount">
                {moneyFormatter.format(results.wantsAmt)}&nbsp;
              </span>
              <span className="results__percent">
                ({results.wantsPostTaxPct}% AT$ | {results.wantsPreTaxPct}% BT)
              </span>
            </div>
          </div>

          <div className="other-info">
            <div>
              <span className="results__type">
                Savings + Needs:&nbsp;
              </span>
              <span className="results__amount">
                {moneyFormatter.format(results.savingsAmt + results.needsAmt)}&nbsp;
              </span>
              <span className="results__percent">
                {/* Add the savings and needs percents together */}
                {/* Round the result to 2 decimal places */}
                ({postTaxIncome ? Math.floor(((results.savingsAmt + results.needsAmt) / postTaxIncome) * 100 * 100) / 100 : 0}% AT$ | {preTaxIncome ? Math.floor(((results.savingsAmt + results.needsAmt) / preTaxIncome) * 100 * 100) / 100 : 0}% BT)


              </span>
            </div>
          </div>
        </div>
      )}

      <div className="actions">
        <Button variant="primary" onClick={saveResults} disabled={!(preTaxIncome && postTaxIncome)}>
          Save Results
        </Button>

        <Button variant="secondary" onClick={resetCalculator}>
          Reset Calculator
        </Button>

        <Button variant="danger" onClick={deleteSavedResults}>
          Delete Saved Results
        </Button>
      </div>

      <div className="description">
        <span>This calculator assumes that:</span>
        <ul>
          <li>20% of pre-tax income is saved</li>
          <li>50% of post-tax income is spent on necessities</li>
          <li>The remaining amount is spent on wants</li>
        </ul>
      </div>
    </Container>
  );
}
